<template>
  <div class="cart-shipping-progress">
    <div class="cart-shipping-progress__bar-holder">
      <div
        class="cart-shipping-progress__bar-progress"
        :style="{ width: `${getPercentageProgress}%` }"
        :class="{
          'cart-shipping-progress__bar-progress--qualify':
            getPercentageProgress >= 100
        }"
      >
        <div class="cart-shipping-progress__shipping-icon">
          <bleach-image
            :key="iconSrc"
            class="cart-shipping-progress__shipping-icon-image"
            :class="{
              'cart-shipping-progress__shipping-icon-image--product': isProduct
            }"
            :src="iconSrc"
            :default-size="[50, 'ar_1']"
            imageQuality="60"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    max: Number,
    value: Number,
    iconSrc: String,
    isProduct: Boolean
  },

  computed: {
    getPercentageProgress() {
      return Math.min((this.value / this.max) * 100, 100)
    }
  }
}
</script>

<style lang="scss">
.cart-shipping-progress {
  margin: 8px 0 16px;
  width: 100%;

  &__bar-holder {
    position: relative;
    width: 80%;
    margin: 0 auto;
    height: 6px;
    background-color: getcolour(bleach_grey_90);
  }

  &__bar-progress {
    position: absolute;
    height: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    background-color: getcolour(bleach_pink_dark);
    transition: background-color 0.1s linear, width 0.2s ease;

    &--qualify {
      background-color: getcolour(bleach_lime);
    }
  }

  // &__shipping-icon {
  //   margin-bottom: 30px;
  // }

  &__shipping-icon-image {
    // flex: 0 0 25px;
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(50%, -50%);
    width: 32px;
    height: 32px;
    // margin-right: 1em;

    &--product {
      width: 48px;
      height: 48px;
    }
  }
}
</style>
